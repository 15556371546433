<template>
  <div class="homess">
    <el-container class="home-container">
      <!-- 头部 -->
      <!-- <el-header style="width:100%;">
        <div v-if="!this.isMobileButton">
          <img
            src="../assets/2.jpg"
            alt
            style="width:120px;height:130px"
            :preview-src-list="srcList"
          />
          <el-button
            type="primary"
            v-for="item in systemList"
            :key="item.code"
            :label="item.name"
            @click="getSystem(item)"
            :value="item"
          >{{item.name}}</el-button>
        </div>
        <div v-else>
          <img
            src="../assets/2.jpg"
            alt
            style="width:40px;height:130px"
            :preview-src-list="srcList"
          />
          <el-button
            type="primary"
            style="width:60px;font-size:1px;"
            v-for="item in systemList"
            :key="item.code"
            :label="item.name"
            size="small"
            @click="getSystem(item)"
            :value="item"
          >{{item.name}}</el-button>
        </div>
        <div>
          <el-popover placement="top" width="160" v-model="visible">
            <div style="text-align: right; margin: 0">
              <span v-for="(value,roleCode) in roleList" v-bind:key="roleCode">
                <el-button type="success" @click="getMenuRoleButton(value)">{{ value.roleName }}</el-button>
              </span>
              <el-button type="info" @click="logout">退出</el-button>
            </div>
            <el-button slot="reference" v-if="!this.isMobileButton">打开</el-button>
            <el-button slot="reference" v-else style="width:60px;">打开</el-button>
          </el-popover>
        </div>
      </el-header> -->
      <el-header style="width:100%;">
      <heards ref="heardsFrom"></heards>
      </el-header>

      <el-container>
        <!-- 菜单边栏 -->
        <el-aside :width="isCollpase ? '64px':'200px'">
          <div class="toggle-button" @click="toggleCollapse">|||</div>
          <el-menu
            background-color="#333744"
            text-color="#fff"
            active-text-color="#409eff"
            :unique-opened="true"
            :collapse="isCollpase"
            :collapse-transition="false"
            router
           
          >
          <!---->
            <el-submenu
              popper-append-to-body
              v-for="item in menuList"
              :key="item.code"
              :index="item.code"
            >
              <template slot="title">
                <i class="el-icon-location"></i>
                <span>{{item.name}}</span>
              </template>

              <el-menu-item
                v-for="child in item.children"
                :key="child.path"
                :is-nest="true"
                class="nest-menu"
                :index="child.path"
                @click="handleSelect(child)" 
              >
                <template slot="title">
                  <i class="el-icon-location"></i>
                  <span>{{child.name}}</span>
                </template>
              </el-menu-item>
            </el-submenu>
          </el-menu>
        </el-aside>

        <!-- 右边栏 -->
        <el-main style="width:400px">
          <router-view :homeMenus="homeMenus"></router-view>
          <experience ref="experienceFrom" v-if="showExperience" />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
//暂时这样设置菜单栏
import heards from './headers.vue';
import { treeShapeList } from "@/api/system/systemCommon/menu.js";
import { listByUser, getRoleByUserApi } from "@/api/system/product/product.js";
import { resetRouter } from "@/router/router.js";
import experience from "@/views/common/experience/index.vue";
import { loginOut } from "@/api/user/user";
export default {
  components: {
    experience,
    heards
  },
  data() {
    return {
      srcList: ["../assets/2.jpg"],
      showExperience: false,
      squareUrl:
        "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
      houstingOption: [],
      housingId: {},
      visible: false,
      isCollpase: false,
      systemList: [],
      queryMenu: {
        productSource: null
      },
      item: {
        type: Object,
        required: true
      },
      menuList: [],
      roleButtons: false,
      roleList: [],
      isMobileButton: false,
      homeMenus:{}
    };
  },
  created() {
    this.getSystemList();
  },
  methods: {
    async logout() {
      loginOut().then(res => {
        this.$message.error("退出成功");
        this.$router.push("/login");
        window.sessionStorage.clear();
      });
    },
    toggleCollapse() {
      this.isCollpase = !this.isCollpase;
    },
    //加载应用
    getSystemList() {
      listByUser().then(res => {
        if (res.result.length == 0 || res.result == undefined) {
          //这里需要加入体验
          this.showExperience = true;
          return;
        } else {
          if (this.$isMobile()) {
            this.isMobileButton = true;
          }
          this.$refs.heardsFrom.menuData = res.result;
          this.systemList = res.result;
          this.getRoleByUserApiButton(res.result[0]);
          console.log(this.$refs.heardsFrom)
    
        }
      });
    },
    //加载菜单
    getSystem(value) {
      //获取角色
      this.getRoleByUserApiButton(value);
      //将其放到回话中，后面请求都需要携带
    },
    getMenuButton(roleCode) {
      this.queryMenu.isHide = 0;
      this.queryMenu.roleCode = roleCode;
      treeShapeList(this.queryMenu).then(res => {
        resetRouter(res.result);
        this.menuList = res.result;
        window.sessionStorage.setItem("routeList", JSON.stringify(res.result));
      });
    },
    openRoleButton() {
      this.roleButtons = !this.roleButtons;
    },
    getRoleByUserApiButton(value) {
      getRoleByUserApi({ productCode: value.code }).then(res => {
        this.roleList = res.result;
        //获取第一个角色作为默认菜单
        this.getMenuButton(res.result[0].roleCode);
        this.$refs.heardsFrom.initData(this.roleList)
      });
    },
    getMenuRoleButton(row) {
      this.getMenuButton(row.roleCode);
    },
    handleSelect(row){
      console.log(row)
      this.homeMenus=row;
    }
  }
};
</script>

<style lang="less" scoped>
.home-container {
  height: 100%;
}
.el-header {
  width: 100%;
  background-color: #00ccff;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  align-items: center;
  color: #fff;
  font-size: 20px;
  > div {
    display: flex;
    align-items: center;
    span {
      margin-left: 15px;
    }
  }
}

.el-aside {
  background-color: #333744;
  .el-menu {
    border-right: none;
  }
}

.el-main {
  background-color: #eaedf1;
}
.toggle-button {
  background-color: #333744;
  font-size: 10px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer;
}
.homess {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  /* min-width: 1366px; */
}
</style>