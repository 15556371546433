<template>
  <div class="nav-menu" :class="{ 'is-open': isMenuOpen }">
    <div class="menu-wrapper">
      <ul
        class="menu-list"
        :class="{ 'is-centered': isMenuCentered }"
        v-if="this.isShow"
      >
        <li
          v-for="(item, index) in menuData"
          :key="index"
          :class="{ 'has-submenu': item.submenu }"
        >
          <button class="menu-toggle-but"   @click="toggleSubMenu(item)">
            {{ item.name }}
          </button>
        </li>
      </ul>
      <!-- <ul class="menu-list-sp" v-else>
        当前系统为: XX 系统
      </ul>
      <button class="menu-toggle" @click="toggleMenu">
        <span>{{ isShow == true ? "当前系统" : "切换系统" }}</span>
      </button> -->
      <div class="user-menu">
        <!-- <el-popconfirm title="这是一段内容确定删除吗？">
                    <el-button type="text" @click="togglePopoverMenu" slot="reference" round>
                        <el-avatar shape="circle" :size="48" src="https://picsum.photos/id/237/200/200"
                            style="margin: -12px 0px;" />
                    </el-button>
                </el-popconfirm> -->
        <!-- title="确定删除吗？" confirmButtonText="确定" cancelButtonText="取消" @confirm="handleDelete"  :visible-arrow="false"-->
        <el-popover width="150">
          <el-button
            type="text"
            @click="togglePopoverMenu"
            slot="reference"
            round
          >
            <el-avatar
              shape="circle"
              :size="48"
              src="https://picsum.photos/id/237/200/200"
              style="margin: -12px 0px"
            />
          </el-button>
          <div style="text-align: right; margin: 0">
              <span v-for="(value,roleCode) in roleList" v-bind:key="roleCode">
                <el-button type="success" @click="getMenuButton(value)">{{ value.roleName }}</el-button>
              </span>
              <el-button type="info" @click="logout">退出</el-button>
            </div>
          <!-- <div>
            <ul>
              <li><a href="#">角色 1</a></li>
              <li><a href="#">角色 2</a></li>
              <li><a href="#">角色 3</a></li>
            </ul>
            <el-button type="info" @click="logout">退出</el-button>
          </div> -->
        </el-popover>
      </div>
      <!-- <ul class="user-dropdown">
    <li><a href="#">角色 1</a></li>
    <li><a href="#">角色 2</a></li>
    <li><a href="#">角色 3</a></li>
  </ul> -->
    </div>
  </div>
</template>

<script>
import { loginOut } from "@/api/user/user";
import { treeShapeList } from "@/api/system/systemCommon/menu.js";
export default {
  name: "NavMenu",
  data() {
    return {
      showPopover: true,
      visible: false,
      isMobileButton: false,
      isDropdownOpen: false,
      isMenuOpen: true,
      isShow: true,
      isMenuCentered: false,
      roleList:[],
      menuData: [
      ],
    };
  },
  methods: {
    initData(row){
      this.$nextTick(() => {
        this.roleList=row;
          });
    },
    togglePopoverMenu() {
    },
    getMenuRoleButton(row) {
      this.getMenuButton(row.roleCode);
    },
    toggleSubMenu(value) {
      this.$parent.$parent.$parent.getRoleByUserApiButton(value)
    },
    toggleMenu() {
      this.isShow = !this.isShow;
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    closeDropdown() {
      this.isDropdownOpen = false;
    },
    handleDelete() {},
    async logout() {
      loginOut().then(res => {
        this.$message.error("退出成功");
        this.$router.push("/login");
        window.sessionStorage.clear();
      });
    },
    getMenuButton(roleCode) {
      this.queryMenu.isHide = 0;
      this.queryMenu.roleCode = roleCode;
      treeShapeList(this.queryMenu).then(res => {
        resetRouter(res.result);
        this.menuList = res.result;
        window.sessionStorage.setItem("routeList", JSON.stringify(res.result));
      });
    },
  },
  mounted() {
    // window.addEventListener('click', this.closeDropdown);
    // this.$refs.userMenu.addEventListener('click', function (event) {
    //     event.stopPropagation();
    // });
  },
  // beforeDestroy() {
  //     window.removeEventListener('click', this.closeDropdown);
  // }
};
</script>
<style scoped>
.nav-menu {
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  height: 100px;
  overflow: hidden;
  transition: height 0.4s ease;
}

.nav-menu.is-open {
  /* height: auto; */
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.menu-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.menu-toggle-but {
  background-color: transparent;
  border: none;
  display: block;
  position: relative;
  margin: 10px 0px;
}

.menu-toggle {
  background-color: transparent;
  border: none;
  display: block;
  position: relative;
}

.menu-toggle span::before,
.menu-toggle span::after {
  background-color: #333;
  content: "";
  height: 2px;
  position: absolute;
  transition: transform 0.3s ease;
  width: 20px;
}

.menu-toggle span::before {
  top: -6px;
}

.menu-toggle span::after {
  bottom: -6px;
}

.menu-toggle.is-active span {
  background-color: transparent;
}

.menu-toggle.is-active span::before {
  transform: translateY(6px) rotate(45deg);
}

.menu-toggle.is-active span::after {
  transform: translateY(-6px) rotate(-45deg);
}

.menu-list-sp {
  display: flex;
  flex: 1;
  list-style: none;
  margin: 0;
  padding: 0;
  margin: 10px 0px;
}

.menu-list {
  display: flex;
  flex: 1;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: 20px;
  /* 添加居中对齐属性 */
}

.menu-list > li {
  margin-right: 20px;
  position: relative;
}

.menu-list > li > a {
  color: #333;
  display: block;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  text-decoration: none;
}

.menu-list > li.has-submenu::after {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #333;
  content: "";
  height: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 0;
}

.submenu-list {
  background: #fff;
  border: 1px solid #ddd;
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 100%;
  z-index: 1;
}

.submenu-list > li {
  margin: 0;
}

.submenu-list > li > a {
  color: #333;
  display: block;
  font-size: 14px;
  padding: 10px;
  text-decoration: none;
}

.submenu-list > li > a:hover {
  background: #f0f0f0;
}

.menu-list > li:hover > .submenu-list {
  display: block;
}

.menu-list > li:hover::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

/* 按钮 */
.user-menu {
  position: relative;
  text-align:right;
  margin: 0;

}

.user-avatar {
  /* 添加 !important 属性 */
  width: 40px !important;
  height: 40px !important;
}

/* 
.user-avatar {
  background-color: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  margin: 0;
  padding: 0;
  width: 40px;
  height: 40px;
} */
.user-avatar i {
  /* 添加 !important 属性 */
  font-size: 20px !important;
}

/* .user-avatar i {
    
  color: #333;
  font-size: 20px;
} */

.user-dropdown {
  background-color: #fff;
  border: 1px solid #ddd;
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: calc(100% + 10px);
  z-index: 2;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.user-dropdown li {
  border-bottom: 1px solid #ddd;
}

.user-dropdown li:last-child {
  border-bottom: none;
}

.user-dropdown li a {
  color: #333;
  display: block;
  font-size: 14px;
  padding: 10px;
  text-decoration: none;
}

.user-dropdown li a:hover {
  background-color: #f0f0f0;
}

/*  */
.user-dropdown {
  .el-popover__content {
    padding: 0;
  }

  ul {
    border: 1px solid #ccc;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    padding: 10px;
  }

  a {
    text-decoration: none;
    color: #333;
  }
}
</style>