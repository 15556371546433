
import { getAction, getByIdAction, postAction, putAction, getByTypeAction } from  '@/api/common/request';
const baseURL = 'xun/common/commonMenu'

//查询
const menuList = (params) => getAction(baseURL + "/list", params);



const addMenu = (params) => postAction(baseURL + "/add", params);

const selectById = (params) => getByIdAction(baseURL + "/selectById", params);

const editMenu = (params) => postAction(baseURL + "/edit", params);

const getTreeMenuApi = (params) => getAction(baseURL + "/getTreeMenu", params);


const treeMenuShapeListApi = (params) => getAction(baseURL + "/treeMenuShapeList", params);
const treeShapeList = (params) => getAction(baseURL + "/v1/treeShapeList", params);
const treeMenuShapeListV1Api = (params) => getAction(baseURL + "/v1/treeMenuShapeList", params);

const menuShapeListApi = (params) => getAction(baseURL + "/v1/menuShapeList", params);


const menuV1ListApi = (params) => getAction(baseURL + "/v1/menuList", params);

export {
    menuList,
    treeShapeList,
    addMenu,
    selectById,
    editMenu,
    getTreeMenuApi,
    treeMenuShapeListApi,
    treeMenuShapeListV1Api,
    menuShapeListApi,
    menuV1ListApi
}

